<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-01-20 14:48:54
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-01-23 18:28:54
 * @FilePath: /yiyun_project/src/views/shortVideo/addShortVideo.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main>
    <div class="stepBox">
      <div
        :class="['step', index != 0 && index != stepList.length - 1 ? 'not_first' : '', index == stepList.length - 1 ? 'last_step' : '', item.step == active_step ? 'active_step' : '']"
        v-for="(item, index) in stepList"
        :key="item.step"
      >
        <div>{{ item.name }}</div>
        <img class="stop_icon" v-if="index != stepList.length - 1" :src="shortImage + 'youjiantou.png'" />
      </div>
    </div>
    <el-form :model="formData" status-icon :rules="rules" ref="ruleForm" label-width="120px">
      <div v-show="active_step == 1">
        <el-form-item label="标题" prop="title">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <el-form-item label="副标题" prop="sub_title">
          <el-input v-model="formData.sub_title"></el-input>
        </el-form-item>
        <el-form-item label="标志" prop="pass">
          <el-select v-model="sign_list" multiple placeholder="请选择">
            <el-option label="热门" :value="1"></el-option>
            <el-option label="推荐" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="formData.status">
            <el-radio :label="1">上架</el-radio>
            <el-radio :label="0">下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序" prop="pass">
          <el-input v-model="formData.sort" type="number"></el-input>
        </el-form-item>
        <el-form-item label="封面" prop="image_url">
          <ReadyUploadSource :path="formData.image_url" @getSource="val => (formData.image_url = val.path)" @removeThis="formData.image_url = ''"></ReadyUploadSource>
          <div class="tips">建议尺寸比例：3:4</div>
        </el-form-item>
        <el-form-item label="分类" prop="cate_id">
          <el-cascader
            v-model="formData.cate_id"
            placeholder="请选择或搜索"
            clearable
            filterable
            :props="{ multiple: true, value: 'id', label: 'catename', children: '_child' }"
            :options="cateList"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="地区" prop="pass">
          <el-select v-model="formData.source" placeholder="请选择">
            <el-option label="内地" :value="1"></el-option>
            <el-option label="港台" :value="2"></el-option>
            <el-option label="日韩" :value="3"></el-option>
            <el-option label="欧美" :value="4"></el-option>
            <el-option label="其他" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年份" prop="pass">
          <el-select v-model="formData.years_name" placeholder="请选择">
            <el-option v-for="item in yearList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input placeholder="请输入内容" v-model="formData.price">
            <template slot="append">积分</template>
          </el-input>
          <div class="tips">短剧没有VIP的情况下整剧的价格</div>
        </el-form-item>
        <el-form-item label="vip价格" prop="vip_price">
          <el-input placeholder="请输入内容" v-model="formData.vip_price">
            <template slot="append">积分</template>
          </el-input>
          <div class="tips">短剧在有VIP的情况下整剧的价格</div>
        </el-form-item>
        <el-form-item label="总集数" prop="episode">
          <el-input type="number" v-model="formData.episode"></el-input>
        </el-form-item>
        <el-form-item label="开始收费集数" prop="pay_episode">
          <el-input type="number" v-model="formData.pay_episode"></el-input>
        </el-form-item>
        <el-form-item label="评分" prop="theater_score">
          <el-input type="number" v-model="formData.theater_score"></el-input>
        </el-form-item>
      </div>
      <div v-show="active_step == 2">
        <!-- 演员信息 -->
        <div style="margin-bottom: 14px">演员信息</div>
        <el-table :data="formData.theater_actor" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column width="140px" label="演员头像" align="center">
            <template v-slot="{ row }">
              <ReadyUploadSource :path="row.actor_avatar" @getSource="val => (row.actor_avatar = val.path)" @removeThis="row.actor_avatar = ''"></ReadyUploadSource>
            </template>
          </el-table-column>
          <el-table-column width="150px" label="演员姓名" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.actor_name"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="150px" label="演员英文名" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.actor_en_name"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="演员标签" align="center">
            <template v-slot="{ row }">
              <div class="labelbox">
                <el-tag :key="index" v-for="(tag, index) in row.label" closable :disable-transitions="false" @close="row.label.splice(index, 1)">
                  {{ tag }}
                </el-tag>
                <el-popover placement="top" @hide="sureAddLabel(1)" width="160" v-model="show_add_pop">
                  <el-input v-model="label_text"></el-input>
                  <div style="text-align: right; margin: 0; margin-top: 16px">
                    <el-button size="mini" type="text" @click="sureAddLabel(1)">取消</el-button>
                    <el-button type="primary" size="mini" @click="sureAddLabel(2, row.label)">确定</el-button>
                  </div>
                  <el-button slot="reference" type="primary" size="mini">+ 添加</el-button>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="150px" label="演员饰演" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.actor_play"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="200px" label="演员类型" align="center">
            <template v-slot="{ row }">
              <el-radio-group v-model="row.actor_type">
                <el-radio :label="1">导演</el-radio>
                <el-radio :label="2">演员</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column width="100px" label="排序" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.sort" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template v-slot="{ $index }">
              <el-button type="text" @click="formData.theater_actor.splice($index, 1)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 14px">
          <el-button type="primary" @click="actoeAdd">+ 添加</el-button>
          <el-button @click="formData.theater_actor = []">清空</el-button>
        </div>
        <div style="margin: 14px 0">剧集信息</div>
        <el-table :data="formData.theater_spec" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column width="140px" label="封面" align="center">
            <template v-slot="{ row }">
              <ReadyUploadSource :path="row.image_url" @getSource="val => (row.image_url = val.path)" @removeThis="row.image_url = ''"></ReadyUploadSource>
            </template>
          </el-table-column>
          <el-table-column label="名称" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.sku_name"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="120px" label="单集价格" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.price" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="120px" label="单集vip价格" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.vip_price" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="200px" label="视频" align="center">
            <template v-slot="{ row }">
              <el-input style="width: 200px" v-model="row.video_url"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="120px" label="时常" align="center">
            <template v-slot="{ row }">
              <el-input style="width: 200px" v-model="row.long_time"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="100px" label="排序" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.sort" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="120px" label="状态" align="center">
            <template v-slot="{ row }">
              <el-switch v-model="row.is_show" :active-value="1" :inactive-value="0"></el-switch>
            </template>
          </el-table-column>
          <el-table-column width="120px" label="虚拟点赞量" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.verb_like_num" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="120px" label="虚拟播放量" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.verb_show_num" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="120px" label="虚拟收藏量" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.verb_collect_num" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="120px" label="虚拟转发量" align="center">
            <template v-slot="{ row }">
              <el-input v-model="row.verb_change_num" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template v-slot="{ $index }">
              <el-button type="text" @click="formData.theater_spec.splice($index, 1)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 14px">
          <el-button type="primary" @click="specAdd">+ 添加</el-button>
          <el-button @click="formData.theater_spec = []">清空</el-button>
        </div>
      </div>
      <div v-show="active_step == 3">
        <el-form-item label="标签">
          <div class="labelbox">
            <el-tag :key="index" v-for="(tag, index) in formData.label" closable :disable-transitions="false" @close="formData.label.splice(index, 1)">
              {{ tag }}
            </el-tag>
            <el-popover placement="top" @hide="sureAddLabel(1)" width="160" v-model="show_add_pop">
              <el-input v-model="label_text"></el-input>
              <div style="text-align: right; margin: 0; margin-top: 16px">
                <el-button size="mini" type="text" @click="sureAddLabel(1)">取消</el-button>
                <el-button type="primary" size="mini" @click="sureAddLabel(2, formData.label)">确定</el-button>
              </div>
              <el-button slot="reference" type="primary" size="mini">+ 添加</el-button>
            </el-popover>
          </div>
        </el-form-item>
        <el-form-item label="简介" prop="description">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="formData.description"></el-input>
        </el-form-item>
        <el-form-item label="图文详情">
          <RichText :richTxt="formData.theater_img" @soninfo="val => (formData.theater_img = val)"></RichText>
        </el-form-item>
      </div>
    </el-form>
    <Preservation :step="{ now: active_step, max: stepList.length }" @preservation="shortVideoSubmit"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import { shortImage } from '@/util/config.js';
import ReadyUploadSource from '@/components/readyUploadSource';
import RichText from '@/components/richText';
import _ from 'lodash';
export default {
  components: {
    Preservation,
    ReadyUploadSource,
    RichText,
  },
  data() {
    return {
      edit_id: null,
      copy_id: null,
      shortImage,
      formData: {
        cate_id: [],
        title: '',
        sub_title: '',
        is_recommend: 0,
        is_hot: '',
        label: [],
        is_end: 0,
        episode: '',
        pay_episode: '',
        image_url: '',
        online_time: '',
        status: 1,
        sort: '',
        description: '',
        theater_img: '',
        theater_score: '',
        source: '',
        price: '',
        years_name: '',
        vip_price: '',
        is_spec: '',
        theater_spec: [],
        theater_actor: [],
      },
      rules: {
        title: [{ required: true, message: '请输入视频标题', trigger: 'blur' }],
        sub_title: [{ required: true, message: '请输入视频副标题', trigger: 'blur' }],
        status: [{ required: true, message: '--', trigger: 'blur' }],
        image_url: [{ required: true, message: '请上传视频封面', trigger: 'blur' }],
        price: [{ required: true, message: '请填写价格', trigger: 'blur' }],
        vip_price: [{ required: true, message: '请填写vip价格', trigger: 'blur' }],
        episode: [{ required: true, message: '请填写集数', trigger: 'blur' }],
        pay_episode: [{ required: true, message: '请填写开始收费集数', trigger: 'blur' }],
        theater_score: [{ required: true, message: '请填写评分', trigger: 'blur' }],
        description: [{ required: true, message: '请填写短剧简介', trigger: 'blur' }],
      },
      stepList: [
        { step: 1, name: '基础信息' },
        { step: 2, name: '演员/剧集' },
        { step: 3, name: '短剧详情' },
      ],
      //   当前步骤
      active_step: 1,
      //   选中的是否是推荐热门
      sign_list: [],
      //  分类列表
      cateList: [],
      //
      show_add_pop: false,
      label_text: '',
    };
  },
  computed: {
    yearList() {
      let arr = [];
      let year = new Date().getFullYear();
      for (let index = 0; index < 10; index++) {
        arr.push(Number(year) - index);
      }
      return arr;
    },
  },
  created() {
    this.getCateList();
  },
  methods: {
    // 获取分类列表
    getCateList() {
      this.$axios.post(this.$api.shortVideo.all_cate_list).then(res => {
        if (res.code == 0) {
          this.cateList = res.result;
          if (this.$route.query.edit_id) {
            this.edit_id = this.$route.query.edit_id;
            this.getDataInfo();
          }
          if (this.$route.query.copy_id) {
            this.copy_id = this.$route.query.copy_id;
            this.getDataInfo();
          }
        }
      });
    },
    // 编辑||复制  查询短剧详情
    getDataInfo() {
      this.$axios
        .post(this.$api.shortVideo.videoInfo, {
          id: this.edit_id || this.copy_id,
        })
        .then(res => {
          for (const key in this.formData) {
            if (key == 'cate_id' || key == 'label') {
              this.formData[key] = String(res.result[key]).split(',');
              continue;
            }
            this.formData[key] = res.result[key];
          }
          if (this.formData.theater_actor?.length) {
            this.formData.theater_actor.map(i => {
              i.label = i.label.split(',');
            });
          }
          if (!this.formData.theater_actor) this.formData.theater_actor = [];
          if (!this.formData.theater_spec) this.formData.theater_spec = [];
          if (res.result.is_hot) this.sign_list.push(1);
          if (res.result.is_recommend) this.sign_list.push(2);
        });
    },
    // 确认添加标签
    sureAddLabel(mode, arr) {
      if (this.label_text == '') return;
      if (mode === 2) arr.push(this.label_text);
      this.label_text = '';
      this.show_add_pop = false;
    },
    // 添加剧集信息
    specAdd() {
      let obj = {
        image_url: '',
        sku_name: '',
        price: '',
        vip_price: '',
        video_url: '',
        long_time: '',
        sort: '',
        is_show: 1,
        verb_like_num: '',
        verb_show_num: '',
        verb_show_num: '',
        verb_change_num: '',
      };
      this.formData.theater_spec.push(obj);
    },
    // 添加演员信息
    actoeAdd() {
      let obj = {
        actor_avatar: '',
        sort: '',
        actor_name: '',
        actor_en_name: '',
        label: [],
        actor_play: '',
        actor_type: 2,
      };
      this.formData.theater_actor.push(obj);
    },
    shortVideoSubmit(mode) {
      if (!mode) {
        this.active_step--;
        return;
      }
      if (mode && this.active_step != this.stepList.length) {
        this.active_step++;
        return;
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.formData);
          obj.cate_id = obj.cate_id.join(',');
          obj.label = obj.label.join(',');
          if (obj.theater_actor?.length) {
            obj.theater_actor.map(i => {
              i.label = i.label.join(',');
            });
          }
          if (this.sign_list?.length) {
            for (let i = 0; i < this.sign_list.length; i++) {
              let data = this.sign_list[i];
              if (data == 1) obj.is_hot = 1;
              if (data == 2) obj.is_recommend = 1;
            }
          }
          if (obj.theater_spec?.length) {
            obj.is_spec = 1;
          } else {
            obj.is_spec = 0;
          }
          if (obj.theater_actor?.length) {
            obj.is_actor = 1;
          } else {
            obj.is_actor = 0;
          }
          let url = this.$api.shortVideo.shortVideoAdd;
          let str = '添加成功';
          if (this.edit_id) {
            url = this.$api.shortVideo.shortVideoEdit;
            obj.id = this.edit_id;
            str = '修改成功';
          }
          this.$axios.post(url, obj).then(res => {
            if (res.code == 0) {
              this.$message.success(str);
              this.$router.push('/shortVideo/shortVideoList');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
  .stepBox {
    display: flex;
    justify-content: space-between;
    background-color: #f5f7fa;
    padding: 14px 8%;

    .step {
      flex: 1;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      font-size: 14px;
      position: relative;
    }
    .stop_icon {
      width: 20px;
      height: 20px;
    }
    .not_first {
      justify-content: flex-end;
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      .stop_icon {
        transform: translateX(20px);
      }
    }
    .last_step {
      justify-content: flex-end;
    }
    .active_step {
      color: rgb(51, 112, 255) !important;
    }
  }
  .el-form {
    margin-top: 16px;
    .el-input,
    .el-textarea {
      max-width: 400px;
    }
    .tips {
      color: #999;
    }
  }
}
</style>
